<template>
  <vue-resizable
    v-slot="{ style }"
    drag-selector=".cursor-grab"
    :active="[]"
    :left="initialValues.x"
    :top="initialValues.y"
  >
    <div
      :style="style"
      class="bg-white call-log-dialog rounded-md"
      :class="isMinimized ? 'max-w-55' : 'w-[570px]'"
      data-cy="call-log-dialog"
    >
      <div
        class="bg-dark p-4 rounded-t-md flex justify-between space-x-2"
        :class="{ 'rounded-b-md max-w-55': isMinimized }"
        data-cy="call-log-dialog-header"
      >
        <div class="flex items-center space-x-4">
          <span
            :title="value.data.call_sessionable.business.business_legal_name"
            class="heading text-white cursor-grab grow text-ellipsis whitespace-nowrap overflow-hidden"
            @dblclick="isMinimized = !isMinimized"
          >
            {{ value.data.call_sessionable.business.business_legal_name }}
          </span>
          <a v-if="dealRoute" :href="dealRoute" target="_blank">
            <icon-base :icon="IconVisit" class="hover:text-white" />
          </a>
        </div>
        <div class="flex space-x-2">
          <icon-base
            v-if="isMinimized"
            :icon="IconMaximize"
            icon-name="Maximize"
            class="text-white cursor-pointer"
            data-cy="maximize-call-log-dialog"
            @click="isMinimized = false"
          />
          <icon-base
            v-else
            :icon="IconSubtract"
            view-box="0 -5 16 16"
            icon-name="Minimize"
            class="text-white cursor-pointer"
            data-cy="minimize-call-log-dialog"
            @click="isMinimized = true"
          />
          <icon-base
            icon="close"
            icon-name="Close"
            class="cursor-pointer"
            @click="emit('close-dialog')"
          />
        </div>
      </div>
      <create-call-note-form
        v-show="!isMinimized"
        class="border-0 rounded-none"
        :call-log="value"
        data-cy="create-call-note-form"
        @call-note:create="emit('close-dialog')"
      >
        <template #supplemental>
          <div
            class="bg-grey-hover flex items-center justify-between px-3 pt-1 text-xs"
          >
            <span class="line-clamp-1 shrink" data-cy="client-name">
              {{ value.data.call_sessionable.client_name }}
            </span>
            <span class="line-clamp-1 shrink" data-cy="workflow-name">
              {{ workflowName }}
            </span>
          </div>
        </template>
      </create-call-note-form>
    </div>
  </vue-resizable>
</template>

<script setup lang="ts">
import type { CallLog } from "@/models/communicationLogs";
import { ref, computed } from "vue";
import CreateCallNoteForm from "@/components/comunicationLogs/forms/CreateCallNoteForm.vue";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconMaximize from "@/components/icons/IconMaximize.vue";
import IconVisit from "@/components/icons/IconVisit.vue";
import VueResizable from "vue-resizable";

const emit = defineEmits<{
  "close-dialog": [];
}>();

const props = withDefaults(
  defineProps<{
    value: CallLog;
    initialValues: { x: number; y: number };
    dealId?: string;
    workflowName?: string;
  }>(),
  {
    initialValues: () => ({ x: 0, y: 0 }),
    dealId: "",
    workflowName: ""
  }
);

const isMinimized = ref(false);

const dealRoute = computed(() => {
  if (!props.dealId) {
    return "";
  }
  return `/deals/${props.dealId}/progress`;
});
</script>

<style>
.call-log-dialog .note-textarea {
  border-radius: 0;
  outline: none;
}
.call-log-dialog .note-textarea:focus {
  box-shadow: none;
}
</style>
